body {
  color: #2e2e2e; }
h1,h2,h3,h4,h5,h6,p,li,td,th,dt,dd,input,select,textarea,pre {
  font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic, Karla", "Noto Sans JP", "ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN" ,"Meiryo UI", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }

.header__logo {
  // @media (min-width: 840px)
  @media (max-width: 839px) {
    width: 120px; } }

header.header {
  // @media (min-width: 840px)
  @media (max-width: 839px) {
    z-index: 500; }

  .sp {
    .header__ec {
      display: block !important;
      width: 31px;
      height: 31px;
      position: relative;
      right: 15px; } } }

.pc {
  .icon-cart {
    content: '';
    display: block;
    width: 22px;
    height: 22px;
    background: url( ../images/icon-cart.png ) no-repeat no-repeat center / contain; } }

img {
  height: auto; }

.content {
  @media (min-width: 840px) {
    margin-top: 35px;
    margin-left: 40px; }
  // @media (max-width: 839px)
  //padding-left: 20px
 }  //padding-right: 20px
.wrap__right {
  @media (min-width: 840px) {
    width: calc( 100% - 175px ); } }
main {
  .header-wrap {
    @media (max-width: 839px) {
      text-align: center; } }
  h1.section-title,
  h2.section-title {
    position: relative;
    text-align: center;
    padding-left: 25px;
    padding-right: 20px;
    display: inline-block;
    span {
      left: 0;
      display: block; }
    .main-title {
      line-height: 100%;
      padding-top: 0;
      text-indent: 13px;
      @media (min-width: 840px) {
        font-size: 28px; }
      @media (max-width: 839px) {
        font-size: 20px; } }
    @media (min-width: 840px) {
      background: url( ../images/title-line.svg ) no-repeat left top 12px;
      left: -35px; }
    @media (max-width: 839px) {
      background: url( ../images/title-line.svg ) no-repeat left top 3px;
      left: -5px; }


    .sub-title {
      padding-top: 20px;
      font-size: 13px; } } }



.the-content {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 840px) {
    padding-top: 90px; }
  @media (max-width: 839px) {
    padding-top: 40px; } }

.is-style {
  &-corporate01 {
    tr {
      border-bottom: 4px solid #fff;
      td {
        font-size: 14px;
        line-height: 160%;
        padding: 5px 15px;
        vertical-align: middle;
        &:first-of-type {
          background: #EDEDED;
          text-align: center;
          @media (min-width: 840px) {
            padding: 15px;
            width: 170px; } } } }
    @media (max-width: 839px) {
      tbody,thead,tfoot,tr,th,td {
        display: block; }
      tr {
        th,td {
          padding: 11px 0; } } } }
  &-spacer01 {
    @media (min-width: 840px) {
      min-height: 150px !important; }
    @media (max-width: 839px) {
      max-height: 60px !important; } }
  &-spacer02 {
    z-index: -1 !important;
    &:before {
      content: '';
      display: block;
      background: #EDEDED;
      width: 100vw;
      height: 210px;
      transform: skew(0deg, -13deg);
      position: relative;
      top: 50px; }
    @media (min-width: 840px) {
      margin-top: 180px;
      left: calc( ( 100vw - 960px )  / 2 * -1 );
 }      // max-height: 320px !important
    @media (max-width: 1000px) {
      left: -100px; }
    @media (max-width: 839px) {
      // max-height: 90px !important
      left: -25px; } }
  &-cover01 {
    height: 215px !important;
    min-height: 215px !important;
    margin-bottom: 40px;
    width: 100%;
    display: block !important;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      left: 0;
      top: 0; }
    // @media (min-width: 840px)
    @media (max-width: 839px) {
      width: calc( 100% + 50px ) !important;
      margin-left: -25px; } }
  &-cover02 {
    margin-bottom: 40px;
    @media (min-width: 840px) {
      height: 430px;
      min-height: 215px !important; }
    @media (max-width: 839px) {
      width: calc( 100% + 50px ) !important;
      min-height: 100px !important;
      height: 300px !important;
      margin-left: -25px; } }

  &-media-text01 {
    p+p {
      margin-top: 50px;
      line-height: 150%; }
    &:not(.has-media-on-the-right) {
      .wp-block-media-text__content {
        padding-right: 0; } }
    &.has-media-on-the-right {
      .wp-block-media-text__content {
        padding-left: 0; } } }
  &-shop01 {
    border-top: 1px solid #E5E5E5;
    tr {
      border-bottom: 1px solid #E5E5E5;
      td {
        padding: 18px;
        font-size: 14px;
        &:nth-of-type(1) {
          width: 190px;
          padding-left: 30px; } } }
    // @media (min-width: 840px)
    @media (max-width: 839px) {
      thead,tbody,tfoot,tr,th,td {
        display: block; }
      tr {
        th,td {
          padding: 15px 0; }
        td {
          &:nth-of-type(1) {
            padding-bottom: 0;
            padding-left: 0; } } } } }

  &-gallery01 {
    background: #EDEDED;
    @media (min-width: 840px) {
      padding: 90px 20px 20px !important; }
    @media (max-width: 839px) {
      padding: 70px 20px 20px !important;
      width: calc( 100% + 50px );
      margin-left: -25px !important;
      box-sizing: border-box; } }

  &-gallery02 {
    @media (min-width: 840px) {
      width: calc( 100% - 75px ); }
    @media (max-width: 839px) {
      ul {
        display: block !important; } }
    li {
      position: relative;
      @media (min-width: 840px) {
        width: calc( 50% - 15px ) !important;
        margin-right: 30px !important;
        margin-bottom: 50px !important; }
      @media (max-width: 839px) {
        width: 100% !important;
        margin-right: 0 !important;
        margin-bottom: 20px !important; }
      &:nth-of-type(2n) {
        margin-right: 0 !important; }
      a {
        color: #2e2e2e !important;
        &:after {
          content: '' !important;
          display: block !important;
          position: absolute !important;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0; } }
      figure {
        display: block !important;
        img {
          height: auto !important; }
        figcaption {
          position: static  !important;
          background: none !important;
          padding: 16px 0 !important;
          font-size: 16px !important;
          color: #2E2E2E !important;
          @media (min-width: 840px) {
            text-align: left !important; }
          @media (max-width: 839px) {
            text-align: center !important; }
          &:before,
          &:after {
            content: 'more';
            vertical-align: middle;
            background: #2e2e2e;
            color: #fff;
            line-height: 100%;
            padding: 5px 7px 9px;
            width: 90px;
            text-align: center;
            margin-right: 15px; }
          &:before {
            display: inline-block; }
          &:after {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 10px; }
          @media (min-width: 840px) {
            &:after {
              display: none !important; } }
          @media (max-width: 839px) {
            &:before {
              display: none !important; } } } } } }
  &-gallery03 {
    max-width: 1240px;
    margin-left: auto !important;
    margin-right: auto !important;
    position: relative;
    box-sizing: border-box;
    padding-left:  20px !important;
    padding-right: 20px !important;

    ul {
      li {
        flex: 0 0 auto !important;
        position: relative !important;
        z-index: 10 !important;
        &:nth-of-type(2n) {
          margin-right: 0 !important; }
        @media (min-width: 840px) {
          width: calc( 50% - 50px ) !important;
          margin-right: 100px !important; }
        @media (max-width: 839px) {
          width: 100% !important;
          margin-right: 0px !important; }

        figure {
          display: block !important;
          height: auto !important;
          img {
            height: auto !important;
            position: relative !important;
            transition: all .3s ease 0s !important; }
          figcaption {
            bottom: auto !important;
            position: static !important;
            background: none !important;
            color: #2e2e2e !important;
            padding: 15px 0 0 !important;

            font-size: 16px  !important;
            font-weight: bold;
            line-height: 130%;
            em {
              font-size: 10px;
              font-weight: normal;
              font-style: normal; } } }
        &:hover {
          z-index: 20 !important;
          img {
            transform: scale( 110% ) translate(-0.2% , -0.2%); } }

        a {
          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0; } } } } }



  &-lower-visual01 {
    .wp-block-media-text__content {
      padding-top: 30px !important; }
    &.has-media-on-the-right {
      .wp-block-media-text__content {
          padding-left: 0 !important; } }
    // @media (min-width: 840px)
    @media (max-width: 839px) {
      .wp-block-media-text__media {
        width: calc( 100% + 50px );
        position: relative;
        left: -25px; } } } }




.wp-block {
  &-media-text {
    &__media {
      // @media (min-width: 840px)
      @media (max-width: 839px) {
        margin-bottom: 20px !important; }
      @media (max-width: 600px) {
        padding-left: 0 !important;
        padding-right: 0 !important; } }
    &__content {
      @media (max-width: 600px) {
        padding-left: 0 !important;
        padding-right: 0 !important; } } }


  &-profit-header01 {
    font-weight: bold;
    @media (min-width: 840px) {
      margin-bottom: 35px; }
    @media (max-width: 839px) {
      margin-bottom: 35px;
      text-align: center; }
    .jp {
      @media (min-width: 840px) {
        display: inline-block;
        font-size: 28px; }
      @media (max-width: 839px) {
        font-size: 22px;
        text-align: center; } }
    .en {
      &:before,
      &:after {
        content: ' - '; }
      @media (min-width: 840px) {
        display: inline-block;
        font-size: 28px;
        margin-left: 20px; }
      @media (max-width: 839px) {
        display: block;
        font-size: 16px;
        margin-bottom: 35px;
        text-align: center;
        margin-top: 10px;
        font-weight: normal; } } }
  &-profit-header02 {
    background: #0095C2;
    border-left: 8px solid #0081A8;
    color: #fff;
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 25px;
    line-height: 150%;
    @media (min-width: 840px) {
      padding: 15px; }
    @media (max-width: 839px) {
      padding: 13px 5px 16px 13px; } }
  &-profit-header03 {
    margin-bottom: 20px;
    font-weight: bold;
    &:before {
      content: '';
      display: inline-block;
      height: 24px;
      width: 24px;
      background: url( ../images/icon-ramen.svg ) no-repeat left center  / contain;
      vertical-align: middle;
      position: relative;
      top: -2px;
      margin-right: 10px; }
    @media (min-width: 840px) {
      font-size: 28px;
      padding: 2px 2px 2px 0px;
      text-align: left; }
    @media (max-width: 839px) {
      text-align: center;
      font-size: 22px;
      text-align: center; } }
  &-profit-header04 {
    line-height: 200%;
    font-weight: bold;
    border: 2px solid #2E2E2E;
    display: inline-block;
    left: 20px;
    @media (min-width: 840px) {
      font-size: 22px;
      padding: 15px 20px; }
    @media (max-width: 839px) {
      font-size: 18px;
      padding: 10px 15px; } }
  &-profit-minimalcontent {
    margin-top: 40px;
    h3 {
      font-size: 14px;
      border-bottom: 1px dotted #707070;
      padding-bottom: 10px;
      line-height: 150%;
      @media (min-width: 840px) {
        padding-left: 25px;
        padding-right: 25px; }
      @media (max-width: 839px) {
        padding-left: 20px; }
      .number {
        @media (min-width: 840px) {
          margin-left: -20px; }
        @media (max-width: 839px) {
          margin-left: -24px; }
        &:empty {
          display: none; } } }
    p {
      font-size: 14px;
      line-height: 160%;
      padding-top: 10px;
      @media (min-width: 840px) {
        padding-left: 25px;
        padding-right: 25px; }
      @media (max-width: 839px) {
        padding-left: 20px;
        padding-right: 0px; } } }
  // &-profit-minimalcontent + &-profit-minimalcontent
  //margin-top: 40px
  &-profit-list01 {
    position: relative;
    @media (min-width: 840px) {
      padding: 0 25px 0 40px;
      margin-top: 40px; }
    @media (max-width: 839px) {
      padding: 0 0px 0 15px;
      margin-top: 20px; }
    &:before {
      content: '';
      display: block;
      width: 2px;
      height: 15px;
      background: #0081A8;
      position: absolute;
      top: 5px;
      @media (min-width: 840px) {
        left: 25px; }
      @media (max-width: 839px) {
        left: 2px; } } }
  &-profit-more01 {
    width: 100%;
    @media (min-width: 840px) {
      height: 280px; }
    @media (max-width: 839px) {
      height: 120px;
      background-size: auto 120px !important;
      background-position: center top 30px !important; } }

  &-profit-numberingtitle01 {
    border-bottom: 1px dotted #707070;
    display: flex;
    flex-direction: row;
    flex-flow: nowrap;
    align-items: flex-end;
    justify-content: flex-start;
    padding-bottom: 12px;
    margin-bottom: 15px;
    .number {
      color: #0095C2;
      font-weight: normal;
      line-height: 120%;
      flex: 1 0 auto;
      padding-right: 15px;
      &:after {
        content: '.'; }
      @media (min-width: 840px) {
        font-size: 48px; }
      @media (max-width: 839px) {
        font-size: 36px; } }
    .title {
      flex: 1 1 80%;
      @media (min-width: 840px) {
        font-size: 18px;
        line-height: 150%; }
      @media (max-width: 839px) {
        font-size: 16px;
        line-height: 130%; } } }
  &-profit-minititle01 {
    font-size: 15px;
    line-height: 180%;
    border-left: 4px solid #0095C2;
    position: relative;
    margin-bottom: 30px;
    box-sizing: border-box;
    @media (min-width: 840px) {
      padding: 15px; }
    @media (max-width: 839px) {
      padding: 5px 15px; }
    &:after {
      content: '';
      display: block;
      width: calc( 100% + 3px );
      position: absolute;
      left: -3px;
      bottom: -10px;
      border-bottom: 1px dotted #707070; } }
  &-profit-step01 {
    margin-bottom: 10px;
    h3 {
      font-size: 15px;
      border-bottom: 1px dotted #707070;
      padding-bottom: 7px;
      padding-left: 25px;
      position: relative;
      line-height: 150%;
      margin-bottom: 15px;
      &:before {
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        background: #0095C2;
        position: absolute;
        left: 3px;
        top: calc( 50% - 7px ); } }
    p {
      padding: 0 10px; } }
  &-profit-step01 + &-profit-step01 {
    @media (min-width: 840px) {
      padding-top: 80px;
      background: url( ../images/step-icon01.svg ) no-repeat left 41.429% top 26px; }
    @media (max-width: 839px) {
      background: url( ../images/step-icon01.svg ) no-repeat center top 10px / auto 25px;
      padding-top: 50px; } } }

.hr-icon-0 {
  background: url( ../images/hr-icon-net.svg ) no-repeat center / auto 165px; }

.hr-icon-1 {
  background: url( ../images/hr-icon-ramen.svg ) no-repeat center / auto 165px; }

.hr-icon-2 {
  background: url( ../images/hr-icon-aircon.svg ) no-repeat center / auto 165px; }

.hr-icon-3 {
  background: url( ../images/hr-icon-repair.svg ) no-repeat center / auto 165px; }

.the-content {
  *[class*="is-style-"] {
    position: relative; }
  *[class*="wp-block-"] {
    position: relative; }
  &>h1,h2,h3,h4,p,table,dl,ul,form,div,section {
    position: relative; }
  &>p,table,dl,ul,form,div,section {
    font-size: 14px; }
  iframe[src*="google.com/maps"] {
    width: 100%;
    height: 280px; }
  &>h3:not([class]) {
    font-size: 22px;
    margin-bottom: 20px;
    line-height: 150%; }
  &>p {
    font-size: 14px;
    line-height: 180%; }
  &>p + p {
    margin-top: 30px; }
  a {
    @media (min-width: 840px) {
      &:not([href*="tel:"]) {
        color: #479FC8; } }
    @media (max-width: 839px) {
      color: #479FC8; } }

  .privacy {
    h2 {
      padding: 0 15px;
      font-size: 28px;
      margin-bottom: 30px; }
    h2 + p {
      margin-bottom: 60px;
      @media (min-width: 840px) {
        padding: 0 15px; }
      @media (max-width: 839px) {
        padding: 0 0; } }
    h3 {
      border-bottom: 1px dotted #707070;
      font-size: 15px;
      line-height: 150%;
      padding-bottom: 7px;
      margin-bottom: 15px;
      @media (min-width: 840px) {
        padding-left: 15px; }
      @media (max-width: 839px) {
        padding-left: 0px; } }
    p + h3 {
      margin-top: 40px; }
    p {
      @media (min-width: 840px) {
        margin-left: 45px; }
      @media (max-width: 839px) {
        margin-left: 15px; } }
    p.lead {
      margin-left: 0; }
    p + p {
      margin-top: 20px; } }
  .wp-block-media-text {
    .wp-block-profit-minititle01 {
      margin-top: 15px; } }
  .wp-block-profit-header04 + .is-style-gallery01 {
    z-index: -1;
    @media (min-width: 840px) {
      margin-top: -60px; }
    @media (max-width: 839px) {
      margin-top: -47px; } }
  // .is-style-lower-visual01 + .wp-block-profit-more01
  //@media (min-width: 840px)
  //  background-position: left 16.000% center
  //  margin-top: -140px
 }  //// @media (max-width: 839px)

.home {
  .title-wrap {
    // @media (min-width: 840px)
    @media (max-width: 839px) {
      text-align: center; } }
  h2.section-title {
    position: relative;
    z-index: 5;
    .main-title {
      text-indent: 0;
      display: block;
      width: 100%;
      box-sizing: border-box; }
    .sub-title {
      font-weight: normal;
      display: block;
      width: 100%;
      box-sizing: border-box; }
    @media (min-width: 840px) {
      background-position: left top 10px;
      padding-left: 35px;
      padding-right: 15px;
      padding-bottom: 10px;
      text-align: left;
      .main-title {
        font-size: 28px;
        left: 0; }
      .sub-title {
        font-size: 12px;
        left: 0;
        padding-top: 20px; } }
    @media (max-width: 839px) {
      background-position: left top 7px;
      background-size:  auto 21px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 10px;
      .main-title {
        font-size: 20px;
        padding-left: 25px;
        padding-right: 12px; }
      .sub-title {
        font-size: 10px;
        padding-top: 15px;
        text-align: center;
        padding-left: 25px;
        padding-right: 12px; } } }
  main {
    box-sizing: border-box;
    @media (min-width: 840px) {
      padding-left: 50px;
      padding-right: 25px; }
    // @media (max-width: 839px)
    &>div,
    &>section {
      width: 100%;
      max-width: 1240px;
      margin-left: auto;
      margin-right: auto;
      box-sizing: border-box;
      @media (min-width: 840px) {
        padding-left: 20px;
        padding-right: 20px; }
      @media (max-width: 839px) {
        padding-left: 0px;
        padding-right: 0px; } } } }


.footer {
  position: relative;
  margin-top: 150px; }

.bread-cramb {
  text-align: right;
  @media (min-width: 840px) {
    margin-top: -15px;
    text-align: right; }
  @media (max-width: 839px) {
    margin-top: 30px;
    text-align: center;
    font-size: 11px; } }

.wp-block-profit-homeblock {
  position: relative;
  @media (min-width: 840px) {
    margin-left: 25px; }
  @media (max-width: 839px) {
    margin-left: 0; }
  .__text {
    margin: 30px 0 60px 0;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    @media (min-width: 840px) {
      max-width: 350px; }
    @media (max-width: 839px) {
      padding: 0 25px; }
    &>* {
      font-size: 14px;
      line-height: 2.2; } }
  @media (min-width: 840px) {
    img.about__img {
      right: calc( ( 100vw - 1240px ) / 2 * -1 - 50px ); } }
  @media (min-width: 1430px) {
    img.about__img {
      right: calc( ( 100vw - 1240px ) / 2 * -1 ); } }
  @media (max-width: 1200px) {
    img.about__img {
      right: -25px; } }
  @media (max-width: 839px) {
    img.about__img {
      right: auto;
      margin-top: 20px; } }

  .wp-block-buttons {
    // @media (min-width: 840px)
    @media (max-width: 839px) {
      width: 100%;
      align-items: center;
      justify-content: center; }
    .wp-block-button {
      a.wp-block-button__link {
        background: #2e2e2e;
        color: #fff;
        width: 150px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px #2e2e2e;
        &:hover {
          background: #fff;
          color: #2e2e2e; } }
      @media screen and (max-width: 839px) {
        a.wp-block-button__link {
          width: 220px;
          margin: 0 auto;
          text-align: center; } } } } }



.is-style-service-homeblock {
  section {
    .__text {
      display: flex;
      flex-direction: row;
      flex-flow: nowrap;
      align-items: stretch;
      justify-content: center;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      flex-flow: wrap;
      align-items: stretch;
      justify-content: flex-start;
      &>* {
        flex: 0 1 auto;
        max-width: 700px;
        margin-left: 0;
        margin-right: 5%;
        margin-bottom: 20px;
        &:last-child {
          margin-right: 0; } }
      // @media (min-width: 840px)
      @media (max-width: 839px) {
        width: 100%;
        margin-left: auto;
        margin-right: auto; } }

    .about__img {
      position: relative;
      object-fit: cover;
      @media (min-width: 840px) {
        margin-left: 25px;
        width: 100%;
        height: 310px; }
      @media (min-width: 1430px) {
        width: calc( 100% +  ( ( 100vw - 1240px ) / 2 ) );
        right: calc( ( 100vw - 1240px ) / 2 * -1 + ( ( 100vw - 1240px ) / 2 ) ); }
      @media (max-width: 839px) {
        width: 100%;
        height: 200px; } } } }



.home {
  .is-style-gallery03 {
    // @media (min-width: 840px)
    @media (max-width: 839px) {
      padding-left: 20px;
      padding-right: 0px;
      figcaption {
        font-size: 12px !important; } } } }



.contact {
  // @media (min-width: 840px)
  @media (max-width: 839px) {
    margin-bottom: 0px;
    padding-bottom: 0; } }

.wpcf7 {
  form {
    .contact__item2 {
      // @media (min-width: 840px)
      @media (max-width: 839px) {
        display: block;
        text-align: center;
        margin-top: 50px;
        a,
        input[type="submit"] {
          display: inline-block;
          width: 40%;
          vertical-align: top;
          margin-bottom: 20px; }
        a+br {
          display: none;
          vertical-align: top; }
        .ajax-loader {} } }

    div.wpcf7-response-output {
      @media (max-width: 839px) {
        margin-top: 0px; } } } }

.post-categories {
  li {
    a {
      pointer-events: none; } } }


.home {
  .main-visual {
    position: relative;
    z-index: 1;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
    @media (min-width: 840px) {
      width: calc( 100% + 75px );
      max-width: calc( 100% + 75px );
      margin-left: -50px; }
    @media (max-width: 839px) {
      width: 100%; }
    &:before,
    &:after {
      content: '';
      display: block;
      width: 1px;
      position: absolute;
      left: 50%;
      z-index: 5; }
    &:before {
      background: #fff;
      bottom: 0;
      @media (min-width: 840px) {
        height: 110px; }
      @media (max-width: 839px) {
        height: 50px; } }
    &:after {
      background: #2E2E2E;
      @media (min-width: 840px) {
        height: 70px;
        bottom: -70px; }
      @media (max-width: 839px) {
        height: 50px;
        bottom: -50px; } }

    @media (min-width: 840px) {
      height: 520px;
      background: url( ../images/main-visual@2x.jpg ) no-repeat center / cover;
      margin-bottom: 120px; }
    @media (max-width: 839px) {
      height: 65vh;
      background: url( ../images/main-visual-sp@2x.jpg ) no-repeat center / cover;
      margin-bottom: 30px; }
    .logo {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
      @media (min-width: 840px) {
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        align-items: center;
        justify-content: center; }
      @media (max-width: 839px) {
        text-align: center;
        img {
          width: 150px;
          margin-top: 35.065%;
          margin-left: auto;
          margin-right: auto; } } }
    h2 {
      color: #fff;
      z-index: 10;
      position: absolute;
      font-weight: bold;
      @media (min-width: 840px) {
        bottom: 36px;
        left: calc( 50% + 21px );
        font-size: 18px;
        line-height: 161.111%; }
      @media (max-width: 839px) {
        width: 100%;
        bottom: 65px;
        text-align: center;
        font-size: 15px;
        line-height: 150%; } }
    .cover {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(to top,  rgba(#2e2e2e,.65) 0%,rgba(#434343,.29) 100%);
      mix-blend-mode: multiply;
      z-index: 3; } } }


.top-news {
  @media (min-width: 840px) {
    margin-top: 100px;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    .top-news__box {
      flex-flow: wrap;
      display: flex;
      justify-content: flex-start;
      .top-news__item {
        flex: 0 1 auto;
        width: calc( ( 100% - 60px ) / 3 );
        margin-bottom: 50px;
        margin-right: 30px;
        &:nth-of-type(3n) {
          margin-right: 0; } } } }
  @media (max-width: 839px) {
    margin-top: 40px; } }
.news {
  .the-content {
    .news__top {
      margin-left: 0;
      .top-news__date {
        margin-left: 0; } }
    .news__title {
      margin-left: 0; } } }

.top-btn {
  z-index: 100; }


.contact__attention {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto; }
.the-form-inputs {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  dl {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0;
    dt {
      font-size: 15px;
      line-height: 150%;
      @media (min-width: 992px) {
        width: 180px; }
      @media (max-width: 991px) {
        width: 100%;
        br {
          display: none; } } }
    dd {
      font-size: 15px;
      line-height: 150%;
      @media (min-width: 992px) {
        width: calc( 100% - 180px ); }
      @media (max-width: 991px) {
        width: 100%; } }
    &.require {
      dt {
        &:after {
          content: '(※)';
          color: red;
          font-size: 10px;
          display: inline-block;
          margin-left: 5px; } } } }
  input[type="text"],
  input[type="email"] {
    width: 100%;
    font-size: 15px;
    padding: 15px; }
  select {
    font-size: 15px;
    width: 100%;
    max-width: 200px;
    padding: 15px; }
  textarea {
    font-size: 15px;
    padding: 15px;
    width: 100%;
    height: 200px; }
  .subject {
    label {
      margin-top: 0px; } } }

.the-form-button {
  text-align: center;
  input {
    line-height: 60px;
    border: 1px solid #0095c2;
    box-sizing: border-box;
    padding: 0 30px;
    font-size: 18px;
    color: #0095c2;
    letter-spacing: 1px;
    text-indent: -1px;
    background: #fff;
    transition: all .3s ease 0s;
    &:hover {
      background: #0095c2;
      color: #fff; } } }


.mw_wp_form_confirm {
  .the-form-inputs {
    dl {
      border-bottom: 1px solid #ccc;
      padding-bottom: 17px; } } }
.contact-complete {
  h2 {
    font-size: 24px;
    letter-spacing: 2px;
    text-indent: -2px;
    margin-bottom: 30px;
    font-weight: normal;
    color: #0095c2; }
  p {
    font-size: 15px;
    line-height: 200%; } }

.not-found {
  h2 {
    margin-bottom: 30px;
    font-weight: normal;
    color: #0095c2; }
  h3 {
    margin-bottom: 30px; }
  @media (min-width: 992px) {
    margin-top: 100px;
    h2 {
      font-size: 48px; }
    h3 {
      font-size: 24px; } }
  @media (max-width: 991px) {
    margin-top: 40px;
    h2 {
      font-size: 24px; }
    h3 {
      font-size: 18px; } } }
.no-entry {
  text-align: center;
  @media (min-width: 992px) {
    margin-top: 90px; }
  @media (max-width: 991px) {
    margin-top: 40px; } }


.wp-pagenavi {
  text-align: center;
  .pages {
    display: none; }
  .previouspostslink,
  .current,
  .page,
  .nextpostslink {
    width: 40px;
    line-height: 40px;
    padding: 0;
    margin: 0 10px;
    display: inline-block;
    text-align: center; } }


.sbi_header_hashtag_icon {
  position: static !important; }
.instagram-fixed-button {
  display: block;
  position: fixed;
  right: 20px;
  bottom: 110px; }
